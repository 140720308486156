/*
JOSEPH P. PASAOA
APP MAIN SCSS | Portfolio Site
*/


*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    font-size: 10px;

    --ff-body: 'Raleway', sans-serif;
    --ff-hand: 'Cabin Sketch', cursive;

    --fs-body: 1.7rem;
    --fs-h1: 2.65rem;   /* myname in sidebar */
    --fs-h2: 1.3rem;    /* my title in sidebar */
    --fs-h3: 3rem;      /* page headers */
    --fs-h4: 2rem;      /* project skill headers, project roles */
    --fs-h5: 1.5rem;    /* project subheaders */

    --fw-bold: 800;

    --lineheight-body: 2.2rem;

    --color-white: #fcfcfc;     // alabaster
    --color-blue: #1ea0d2;      // curious blue
    --color-primary: #ffac10;   // sun
    --color-copper: #311102;    // buccaneer
    --color-midnight: #0e1b3d;  // blue zodiac
    // --color-midnight: #0e1b3d;  // blue zodiac

    --pad-min: 3px;
    --pad-windowleft: 1.5vw;
    --pad-pageleft: 5.2rem;

    --space-xs: .5rem;
    --space-sml: 1.5rem;
    --space-med: 2.5rem;
    --space-lrg: 3.5rem;

    --b-rad: 5px;
}

// Medium devices (tablets, 992px and smaller)
@media (max-width: 992px) {
    :root {
        --fs-body: 1.5rem;
        --fs-h4: 1.5rem;
        --fs-h5: 1.2rem;

        --lineheight-body: 1.9rem;

        --space-xs: .3rem;
        --space-sml: 1rem;
        --space-med: 2rem;
        --space-lrg: 2.5rem;
        --pad-pageleft: var(--space-lrg);

    }

    .screencap {
        min-width: 0;        
    }
}
// Small devices (landscape phones, 768px and smaller)
@media (max-width: 768px) {
    :root {
        --space-lrg: 1.4rem;
    }
}

body {
    background-color: #171111; /* to cover viewport completely when screen is dragged  */

    font-family: var(--ff-body);
    font-size: var(--fs-body);

    color: var(--color-white);
}

html, body, .root, .App, #grid-base {
    height: 100%;
    width: 100%;
}

.text {
    font-family: var(--ff-body);
    font-size: var(--fs-body);
    color: var(--color-white);
    line-height: var(--lineheight-body);
    white-space: pre-line;

    &.para {
        margin-bottom: 16px;
    }
}


// VARIABLES
$name-transition: opacity .9s ease-out;

@mixin flex-line($direction, $col-justify: flex-start, $col-align: flex-start) {
    display: flex;
    flex-direction: $direction;
    flex-wrap: nowrap;
    justify-content: $col-justify;
    align-items: $col-align;
}

@mixin flex-center($col-justify: flex-start, $col-align: center) {
    display: flex;
    flex-direction: column;
    justify-content: $col-justify;
    align-items: $col-align;
}

@mixin nav-faicon-in-base() {
    transform-origin: left center;
    animation: nav-faicon-in .2s ease-out 0s 1 forwards;
}

@keyframes nav-faicon-out {
    from { transform: scale(1); }
    to { transform: scale(1.4); }
}
@keyframes nav-faicon-in {
    from { transform: scale(1.4); }
    to { transform: scale(1); }
}


.myButton {
    @include flex-center(center, center);
    width: 10rem;
    height: 2.6rem;
    padding: 1px 11px 1px 11px;
    background: linear-gradient(to bottom, #2d2d2de3 5%, #0a0607ed 100%);
    border: 2px solid var(--color-white);
    box-shadow: 0px 7px 6px -6px #000000;
    border-radius: var(--b-rad);
    cursor: pointer;

    color: var(--color-white);
    font-family: Trebuchet MS;
    font-size: 1.3rem;
    font-weight: var(--fw-bold);
    text-decoration: none;
    text-shadow: 0px 1px 0px #333333;

    transition: border .3s ease-out;
}
.myButton:hover {
    background: linear-gradient(to bottom, #0e1b3de3 5%, #0a060757 100%);
    border-color: #94fbe8;
}
.myButton:active {
    position: relative;
    top: 1px;
}


// HEADERS
h1 {
    font-family: var(--ff-body);
    font-size: var(--fs-h1);
    text-transform: uppercase;

    color: #fff;
    text-shadow: 0px 2px 2px #111;

    &.namebox__name {
        position: absolute;
        margin-bottom: 0.5rem;

        white-space: nowrap;

        transition: $name-transition;

        &.namebox__name--brand {
            top: -.5rem;
            left: -.15rem;

            font-size: 3.5rem;
        }
    }
}

h2 {
    font-family: var(--ff-body);
    font-size: var(--fs-h2);
    font-weight: var(--fw-bold);
    text-transform: lowercase;

    color: #000;

    &.namebox__subname {
        position: absolute;
        top: 5.3rem;

        white-space: nowrap;

        transition: $name-transition;

        &.colored {
            color: var(--color-primary);
        }
    }
}

h3 {
    font-family: var(--ff-body);
    font-size: var(--fs-h3);
    font-weight: var(--fw-bold);
    text-transform: lowercase;
    color: var(--color-blue);
    text-shadow: 14px 4px 8px #000;

    &.page__header {
        position: relative;
        left: -2rem;

        margin-bottom: 7rem;
        // position: fixed;
        // top: 52.6rem;
        // left: 101rem;
    }
}

h4 {
    font-family: var(--ff-body);
    font-size: var(--fs-h4);
    font-weight: var(--fw-bold);
    text-transform: lowercase;
    color: var(--color-white);
    text-shadow: 11px 4px 2px #000;

    &.header-section {
        color: var(--color-primary);
    }
        
    &.card--project__role {
        // margin-top: 1.5rem;
        // margin-bottom: .5rem;
        color: var(--color-blue);
    }
}

h5 {
    font-family: var(--ff-body);
    font-size: var(--fs-h5);
    font-weight: var(--fw-bold);
    text-transform: lowercase;
    color: var(--color-white);
    text-shadow: 11px 4px 2px #000;
}

.link--homepursuit {
    text-shadow: 2px 3px 1px #000;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 900;

    transition: color .4s ease-in;

    &:link, &:visited {
        color: var(--color-white);
    }
    &:hover, &:active {
        color: #4242ea;
    }
}

.link--topreturn {
    font-family: var(--ff-body);
    font-size: var(--fs-h5);
    text-shadow: 11px 4px 2px #000;
    text-transform: uppercase;
    font-weight: var(--fw-bold);
    text-decoration: none;

    &:link, &:visited {
        color: #fcfcfc;
    }
    &:hover, &:active {
        // text-decoration: underline overline;
        color: var(--color-blue);
    }
}


// BASE & SIDEBAR
.App {
    background-image: linear-gradient(180deg, rgba(33, 16, 16, 0.62) 28%,rgba(12, 7, 5, 0.76) 100%), url(./assets/images/bgs/contemp-comp-gtm.jpg);
    background-size: cover;
    background-color: #fff;
    background-position: left bottom;
    background-attachment: fixed;
    height: 100vh;
}

.app__fadebg {
    position: fixed;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, #080808a6, #0f1213aa);
    transition: opacity .8s ease-out 1s;
}

#grid-base {
    display: grid;
    grid-template-columns: minmax(16.5rem, 14vw) 1fr;
    grid-template-rows: 1fr;
}

.topbar {
    @include flex-line(row, space-between, flex-start);
    position: absolute;
    height: 11rem;
    width: 100%;
    z-index: 1000;
    transition: transform .6s ease-out;

    .topbar__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        // border-right: solid 1px #8e6666;
        background-image: linear-gradient(to bottom, #b17029 22%, #481b0a);
        /* background-image: linear-gradient(to bottom, #4bc5ffb0, #011d46); */
        /* background-image: linear-gradient(to bottom, #b99556b0, #271a0287); */
        /* background-image: linear-gradient(to bottom, #4bc5ffb0, #011d46); */
        /* background-image: linear-gradient(to bottom, #A38383b0, #5C3828); */
        /* background-image: linear-gradient(to bottom, #4bc5ffb0, #0d2c5a); */
        clip-path: polygon(0px 0px, 100% 0px, 100% 8.4rem, 10.90% 10rem, 0px 9.1rem);
    }

    display: none;
}

.sidebar {
    @include flex-line(column);
    position: relative;
    height: 100%;
    min-width: 16.5rem;
    z-index: 1000;
    transition: transform .6s ease-out;

    .sidebar__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        // border-right: solid 1px #8e6666;
        background-image: linear-gradient(to bottom, #e69129b3, #5a070785);
        /* background-image: linear-gradient(to bottom, #4bc5ffb0, #011d46); */
        /* background-image: linear-gradient(to bottom, #b99556b0, #271a0287); */
        /* background-image: linear-gradient(to bottom, #4bc5ffb0, #011d46); */
        /* background-image: linear-gradient(to bottom, #A38383b0, #5C3828); */
        /* background-image: linear-gradient(to bottom, #4bc5ffb0, #0d2c5a); */
        clip-path: polygon(0px 0px, 89% 0px, 100% 10.4rem, 83% 100%, 0px 100%);
    }
}

.namebox {
    position: absolute;
    top: 5.5rem;

    margin: var(--pad-min) var(--pad-windowleft);
}

.iconsbar {
    @include flex-line(column);

    width: 100%;
    margin-top: 17rem;
    padding: var(--pad-min) var(--pad-windowleft);

    .link-faicon {
        &:link, &:visited {
            position: relative;
            margin: var(--pad-min) 0 8px 0;
        
            font-size: 2.8rem;
            color: var(--color-midnight);
            filter: drop-shadow(0px 0px 4px var(--color-white));
            opacity: .7;

            svg {
                @include nav-faicon-in-base();
            }

            &::after {
                content: attr(data-name);
                position: absolute;
                top: 0.5rem;
                left: 4.7rem;
                white-space: nowrap;
                font-size: 1.3rem;
                font-weight: var(--fw-bold);
                filter: drop-shadow(0px 0px 5px var(--color-white));
                color: transparent;
                opacity: 0;

                transition: color .1s ease-in, opacity .4s ease-in;
            }

        }
        &.link-faicon--resume:link, &.link-faicon--resume:visited {
            margin-left: 2px; // for centering resume icon if list is left-justified
            margin-top: 4px;

            font-size: 2.6rem;
        }
        &:hover, &:active {
            opacity: 1;

            color: var(--color-white);
            filter: none;

            & svg {
                animation: nav-faicon-out .2s ease-out 0s 1 forwards;
            }

            &::after {
                color: var(--color-white);
                opacity: 1;
            }
        }
    }
}

.navlist {
    @include flex-line(column);

    padding: 15rem var(--pad-windowleft) 8rem calc(var(--pad-windowleft) - .2vw);
    width: 100%;

    li {
        margin-bottom: 11px;

        &:last-child::before {
            content: "⤙⤚";
            position: relative;
            top: -.6rem;
            color: var(--color-white);
            font-size: 2rem;
        }

        .link-navlist {
            &:link, &:visited {
                font-family: var(--ff-body);
                font-size: 1.5rem;
                letter-spacing: 1px;
                font-weight: var(--fw-bold);
                text-decoration: none;
                text-transform: lowercase;

                color: var(--color-white);
                text-shadow: 0px 2px 2px #000;

                .faicon--iconbar {
                    position: relative;
                    left: 2px;
                    opacity: 0;
                    color: var(--color-primary);

                    transition: opacity .5s ease-out;
                }

                div {
                    position: relative;
                    transition: color .1s ease-in, text-shadow .2s ease-in;

                    &::after {
                        content: "✧";
                        position: absolute;
                        top: .1rem;
                        right: -1.7rem;
                        width: min-content;
                        height: 100%;
                        color: var(--color-white);
                        opacity: 0;

                        transition: opacity .5s ease-out;
                    }
                }
            }
            &:hover, &:active {
                color: var(--color-blue);

                div::after {
                    opacity: 1;
                }

                .faicon--iconbar {
                    opacity: 1;
                }
            }
            &.active {
                color: var(--color-primary);
                text-shadow: 0px 1px 1px #000;
                cursor: default;

                div::after {
                    content: "✦";
                    color: var(--color-primary);
                    opacity: 1;
                }

                .faicon--iconbar {
                    opacity: 1;
                }
            }
        }

        &:last-child .link-navlist div::after {
            display: none;
        }
    }
}

.dropdown-converter {
    position: absolute;
    top: 5.3rem;
    right: 1.5rem;
}

.dropdown-toggle-btn {
    display: none;
    color: var(--color-blue);
    font-family: var(--ff-body);
    font-size: 1.5rem;
    letter-spacing: 1px;
    font-weight: var(--fw-bold);
    text-decoration: none;
    text-transform: capitalize;
    text-shadow: 0px 2px 3px #000;
    border: none;
    background-color: transparent;
}

.dropdown-converter .dropdown-toggle-btn {
    display: block;
    outline: none;
}

.dropdown-converter .navlist {
    position: absolute;
    top: 0.5rem;
    right: -1.5rem;
    z-index: 2;
    background: #62351cf7;
    visibility: hidden;
    height: 17rem;
    margin-top: 2rem;
    padding: 1.1rem 1.4rem 1.1rem 3rem;
    align-items: flex-end;
    transform: translateX(-40%);
    transition: visibility .15s ease-out;
}

.dropdown-converter:hover .navlist {
    visibility: inherit;
    pointer-events: auto;
}


// STAGES
.stage { // static viewport containter
    position: relative;
    overflow-y: auto;
    // background-image: linear-gradient(180deg, rgba(53, 27, 27, 0.89) 28%, #1d100adb 100%);
}

.page { // scrolling canvas
    @include flex-line(column);

    position: relative;
    min-height: 100vh;
    padding: 11rem var(--space-lrg) 6rem var(--pad-pageleft);

    &.page--home {
        padding-top: 11.6rem;
    }
}

.page--home {
    .home__topline, .home__mynameis {
        @include flex-line(row);
    }

    .home__greet {
        margin-right: var(--space-sml);
    
        font-family: var(--ff-body);
        font-size: var(--fs-h4);
        font-weight: var(--fw-bold);
        text-transform: lowercase;
        color: var(--color-white);
        text-shadow: 11px 4px 2px #000;
    }

    .home__mynameis div {
        margin-left: 6px;
        position: relative;
        top: -9px;

        font-family: var(--ff-body);
        font-size: 3.6rem;
        font-weight: var(--fw-bold);
        text-transform: lowercase;
        color: var(--color-blue);
        text-shadow: 14px 4px 8px #000;
    }

    .home__myname {
        margin-bottom: var(--space-lrg);

        font-family: var(--ff-hand);
        // font-family: 'Indie Flower', sans-serif;
        font-size: 7.7rem;
        color: var(--color-white);
        text-shadow: 2px 2px 15px #000;
    }

    .text.para {
        line-height: 1.8;
    }

    .home__aboutme {
        margin-top: 1rem;
        max-width: 52rem;
    }

    .text strong {
        font-weight: var(--fw-bold);
        text-shadow: 2px 3px 1px #000;
        color: var(--color-primary);
    }

    .valediction {
        // font-family: 'Indie Flower', sans-serif;
        font-size: 2.2rem;

    }
}

.page--listprojects {
    .card--project {
        @include flex-line(column);

        position: relative;
        width: 100%;
        max-width: 93rem;
        margin-bottom: 5.5rem;

        @media (max-width: 768px) {
            max-width: 50rem;
        }

        .cp__toprow {
            @include flex-line(row, flex-start, baseline);

            h3.card--project__title {
                margin-right: var(--space-med);

                color: var(--color-primary);
            }

            .project-role {
                font-weight: var(--fw-bold);
                text-transform: lowercase;
            }
        }

        .cp__middlerow, .cp__bottomrow {
            display: grid;
            grid-template-columns: 1fr 2fr;
            width: 100%;
            margin-top: var(--space-sml);
        }

        .cp__middlerow {
            .card--project__images {
                @include flex-line(row);
    
                .screencap {
                    width: 100%;
                    min-width: 20rem;
                    max-width: 32rem;
                    height: auto;
                    object-fit: cover;
                    // margin: 3px 3px 3px 2vw;
                    border-radius: var(--b-rad);
                    border: solid 1px #fff;
                    box-shadow: 0px 6px 11px #000;
            
                    &[alt="SuitApp screencap"],
                    &[alt="Middleware Demo screencap"] {
                        object-position: left top;
                    }
                }
            }

            .cp__midbox {
                @include flex-line(column, space-around, flex-start);

                margin-left: var(--space-med);

                .card--project__description {
                    color: #ccc;
                }
    
                .card--project__btns {
                    @include flex-line(row, flex-start, center);
        
                    width: 100%;
                    margin-top: var(--space-sml);
        
                    .myButton:last-child {
                        margin-left: var(--space-med);
                    }
                }
            }
        }

        .cp__bottomrow {
            h5.card--project__subheader {
                margin-top: var(--space-sml);
                margin-bottom: var(--space-xs);

                color: var(--color-primary);

                &:first-child {
                    margin-top: 0;
                }
            }

            .card--project__techlist {
                @include flex-line(column, flex-start, flex-end);

                padding-top: 2px;

                text-align: right;
                list-style: none;
                font-family: var(--ff-hand);
                font-size: var(--fs-h4);
            }

            .cp__bottomrow__flex {
                @include flex-line(column);

                margin-left: var(--space-med);

                .project--features li {
                    margin-left: 2rem;

                    &:first-line {
                        margin-left: -1.4rem;
                    }
                }

                .project--features li::before {
                    content: "➢";
                    position: relative;
                    top: 2px;     
                    left: -6px;
                    margin-right: .8rem;
                    color: #777;
                }
            }
        }

        // .intro__card--project {
        //     @include flex-line(column);

        //     padding-right: 2.5rem;

        //     .project--subheader {
        //         font-size: 1.7rem;
        //         color: var(--color-blue);
        //     }
        // }

        .aws-sld {
            &--exit {
                &.aws-sld--moveRight, &.aws-sld--moveLeft {
                    animation: scaleOut var(--slider-transition-duration) both
                    var(--transition-bezier);
                }
            }
        }
          
        @keyframes scaleOut {
            to {
                transform: scale(0.85);
            }
        }

        .aws-btn {
            --slider-height-percentage: 56%;
            --slider-transition-duration: 1000ms;
            --organic-arrow-thickness: 8px;
            --organic-arrow-border-radius: 0px;
            --organic-arrow-height: 22px;
            --organic-arrow-color: var(--color-blue);
            --control-button-width: 5%;
            --control-button-height: 25%;
            --control-button-background: transparent;
            --control-bullet-color: #805608;
            --control-bullet-active-color: #92630a;
            --loader-bar-color: var(--color-blue);
            --loader-bar-height: 1px;
        }

        // .bottom__card--project {
        //     display: grid;
        //     grid-template-rows: 1fr;
        //     grid-template-columns: 23rem auto;

        //     padding-top: 2rem;
        //     padding-left: 1.5rem;
        //     width: 100%;
        // }



        // .details__card--project {
        //     @include flex-line(column);

        //     padding: 0 2.5rem 0 3.5rem;
        // }
    }
}

.page--skills .section {
    @include flex-line(row);
    flex-wrap: wrap;

    position: relative;
    border: solid 15px #222d4a94;
    border-radius: 4rem;
    padding: 2.8rem 3rem .6rem 7rem;
    margin-bottom: 4rem;
    width: 76.5rem;
    // background-image: linear-gradient(180deg, #0d175af5, #275c8ae3);

    li {
        position: relative;
        width: 12rem;
        margin-bottom: 3rem;
        @include flex-center(center, center);

        .icon-skill {
            height: 5.4rem;
            max-width: 5.4rem;
            filter: drop-shadow(2px 2px 3px #000);

            &.react {
                object-fit: cover;
            }
            &.expressjs {
                border-radius: .7rem;
                background-color: #e5e5e5;
            }
            &.github {
                background-color: var(--color-white);
                border-radius: 50%;
            }
            &.drawio {
                border-radius: 1rem;
            }
            &.basecamp {
                max-width: none;
            }
        }

        &:first-child { // styles section headers
            position: absolute;
            top: -13px;
            left: -4px;
            white-space: nowrap;
            align-items: flex-start;
        }

        &::after {
            content: attr(data-skill);

            position: absolute;
            bottom: -1.6rem;
            left: 50%;
            white-space: nowrap;
            transform: translateX(-50%);

            font-size: 1.2rem;
        }
    }

    &:last-child {
        margin-bottom: var(--pad-min);
    }
}

.page--contactme {
    @include flex-line(column);

    .form-container {
        position: relative;
        width: 100%;

        .submitted-msg {
            @include flex-center(center, center);

            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 10;
            top: 7rem;
            left: 3rem;

            font-size: 4rem;
            font-family: var(--ff-hand);
            color: var(--color-white);
            line-height: 5rem;
        }
    }

    .form-contactme {
        @include flex-line(column);

        width: 100%;
        transition: opacity .4s ease-out;

        label {
            margin-left: .7rem;
            margin-bottom: 1.8rem;
            color: var(--color-primary);
            font-size: 1.4rem;
            font-weight: var(--fw-bold);
            text-transform: lowercase;
        }

        input[type=text], input[type=email] {
            all: unset;
            border-bottom: solid 2px #777;
            padding: .1rem 2.2rem .2rem 2.2rem;
            font-size: 1.9rem;

            &:focus {
                border-bottom-color: var(--color-primary);
            }
        }

        input[name=nameTxt], input[name=emailTxt] {
            width: 29rem;
        }

        input[name=msgTxt] {
            width: 63rem;
            margin-top: 4rem;
        }
    }
}



// Medium devices (tablets, 992px and lower)
@media (max-width: 992px) {

    .page.page--home {
        padding-top: 13rem;
    }
    
    .page--home .home__mynameis div {
        font-size: 3rem;
    }
    
    .page--home .home__myname {
        font-size: 5.5rem;
    }
    
    .page--home .text.para {
        margin-right: 5rem;
    }

    h3.page__header {
        left: 0px;
    }

    .page--listprojects .card--project .cp__middlerow .card--project__images .screencap {
        min-width: 0;        
    }
}


// Small devices (landscape phones, 768px and smaller)
@media (max-width: 768px) {
    h1.namebox__name.namebox__name--brand {
        top: 0px;
    }
    h2.react-reveal.namebox__subname.colored {
        top: 3.5rem !important;
    }

    #grid-base {
        @include flex-line(column);
    }

    .topbar {
        display: flex;
    }

    .sidebar {
        display: none;
    }

    .namebox {
        position: relative;
        top: .3rem;
        left: .5rem;
    }

    .iconsbar {
        position: absolute;
        top: .1rem;
        right: 0rem;
        flex-direction: row-reverse;
        white-space: nowrap;
        margin-top: 0.3rem;
        padding: var(--pad-min);
        width: initial;
        overflow: hidden;

        .link-faicon:link, .link-faicon:visited {
            position: relative;
            margin: 0 var(--space-lrg) 0 var(--pad-min);
            font-size: 3.8rem;
        }

        .link-faicon.link-faicon--resume:link, .link-faicon.link-faicon--resume:visited {
            margin-left: .9rem;
            margin-top: .23rem;
            font-size: 3.3rem;
        }
    }

    .navlist {
        padding: .5rem var(--pad-windowleft) var(--pad-min) var(--pad-windowleft);
        width: initial;
    }

    .page--home .home__mynameis div {
        top: -7px;
        font-size: 2.3rem;
        color: var(--color-primary);
    }

    .page--home .home__myname {
        font-size: 3.7rem;
    }

    .page {
        padding: 1rem 2rem;;
    }

    .page--home .text.para {
        margin-right: 0rem;
    }

    h3.page__header {
        margin-top: 10rem;
        margin-bottom: 3rem;
    }

    .page--listprojects .card--project .cp__toprow {
        flex-direction: column;
        width: 100%
    }

    .page--listprojects .card--project .cp__toprow h3.card--project__title {
        margin-bottom: .5rem;
    }
    
    h4.card--project__role {
        align-self: flex-start;
    }
    
    .page--listprojects .card--project .cp__middlerow,
    .page--listprojects .card--project .cp__bottomrow {
        display: flex;
        flex-direction: column;
    }

    .page--listprojects .card--project .cp__middlerow .card--project__images .screencap {
        margin-bottom: 2rem;
    }

    .page--listprojects .card--project .cp__middlerow .cp__midbox {
        margin-left: .5rem;
    }

    .page--listprojects .card--project .cp__bottomrow .card--project__techlist,
    .page--listprojects .card--project .cp__bottomrow .cp__bottomrow__flex {
        align-items: flex-start;
        margin-left: .5rem;
    }

    .page--listprojects .card--project .cp__bottomrow .card--project__techlist,
    ul.project--features,
    .cp__bottomrow p.text--copy {
        margin-bottom: 2rem;
    }

    .page--skills .section {
        padding: 2.8rem 0rem .6rem 0rem;
        width: 30rem;
    }

    .page--skills .section li {
        width: 13rem;
    }

    .stage {
        width: 100%;
    }

    .page--contactme .form-contactme input[name=nameTxt], .page--contactme .form-contactme input[name=emailTxt] {
        width: 85%;
    }

    .page--contactme .form-contactme input[name=msgTxt] {
        width: 85%;
    }

    .navlist li .link-navlist:link div::after, .navlist li .link-navlist:visited div::after {
        top: .2rem;
        right: -1.2rem;
        font-size: 1.2rem;
    }

    .navlist li:last-child::before {
        right: -6.3rem;
    }

    .navlist li .link-navlist:link, .navlist li .link-navlist:visited {
        text-align: right;
    }
}