/*
Joseph P. Pasaoa
INDEX CSS STYLING | Portfolio Site
*/


/* FONT INITS 
font-family: 'Raleway', sans-serif;
font-family: 'Indie Flower', sans-serif;
*/
@font-face {
  font-family: 'Raleway';
  src: url('./assets/fonts/Raleway/Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} 
@font-face {
  font-family: 'Cabin Sketch';
  src: url('./assets/fonts/Cabin_Sketch/CabinSketch-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


/* INDEX STYLING */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
